import React from "react";
import "../styles/hackathon.css"

import Flyer from "../assets/hackathon/HackathonFlyer.png"

const Hackathon = () => {
    return (
        <div className="hackathon">
            <img src={Flyer}/>
            <h1>This page is under construction! Check back as the date approaches!
            </h1>
        </div>
    )
}

export default Hackathon;